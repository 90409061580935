export default {
  tutorial: [
    {
      title: 'Ordre aléatoire',
      icon: 'icn-algo.svg',
      explanation: 'Les questions vous sont posées dans un ordre aléatoire.',
    },
    {
      title: 'Vous n\'avez le droit qu\'à une seule passation',
      icon: 'icn-algo.svg',
      explanation: 'En revanche, cette passation peut être interrompue et reprise quand vous le voulez\u00A0: quand vous vous reconnecterez, vous reprendrez là où vous en êtes resté.e.',
    },
    {
      title: 'Pas de limite de temps !',
      icon: 'icn-temps.svg',
      explanation: 'Prenez le temps nécessaire pour terminer votre parcours. \nSi une question est chronométrée, cela vous sera indiqué.',
    },
    {
      title: 'Des tutos pour apprendre',
      icon: 'icn-tutos.svg',
      explanation: 'Accédez à des tutos pour apprendre davantage \nsur chaque question et progresser.',
    },
    {
      title: 'Un niveau de difficulté adapté',
      icon: 'icn-algo.svg',
      explanation: 'En fonction de vos réponses,\nPix adapte la difficulté des questions. ',
    },
  ],
};
