import { action } from '@ember/object';
import Component from '@glimmer/component';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';
import { A } from '@ember/array';

export default class CategProposals extends Component { 
  items = A(this.proposalsItems);
  categories = this.categ;

  get proposalsItems() {
    const arrayProposals = proposalsAsArray(this.proposalsAndCateg[1]);
    const proposalsFormat = arrayProposals.map((itemNoFormat) => itemNoFormat.replace('<br>', ''));
    return proposalsFormat;
  }

  get categ() {
    const categElement = A([]);
    const categName = proposalsAsArray(this.proposalsAndCateg[0]);
    categName.map((cname) => categElement.push({ 'name' : cname, 'categItems' : A([]) }));
    return categElement;
  }

  get proposalsAndCateg() {
    const splitTest = this.args.proposals.split('--\n');
    splitTest.shift();
    return splitTest;
  }
  
  @action
  determineForeignPosition({ items }) {
    return items.length;
  }

  @action
  dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
    if (sourceList === targetList) return;
    const responseFormat = [];
    const item = sourceList.objectAt(sourceIndex);

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, item);
    
    this.categories.forEach((category, indexCat) => {
      let responseCateg = '';
      const indexResponse = category.categItems.map((resp) => this.proposalsItems.indexOf(resp) + 1);
      if (indexCat !== this.categories.length - 1) {
        indexResponse.push('\n');
      }
      responseCateg += indexResponse.toString();
      responseFormat.push(responseCateg);
    });
    this.args.answerChanged(responseFormat, this.items.length);
  }
}
