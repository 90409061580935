import { action } from '@ember/object';
import Component from '@glimmer/component';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';
import { A } from '@ember/array';

export default class OrdoProposals extends Component { 
  items = A(this.ordoItems);
  response = A([]);
  responseIndex = A([]);

  get ordoItems() {
    const ordoItems = proposalsAsArray(this.args.proposals);
    const ordoItemsFormat = ordoItems.map((itemNoFormat) => itemNoFormat.replace('<br>', ''));
    return ordoItemsFormat;
  }

  @action
  dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
    if (sourceList === targetList && sourceIndex === targetIndex) return;
  
    const item = sourceList.objectAt(sourceIndex);

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, item);
    this.responseIndex.clear();
    this.response.forEach((element) => {
      const initialItemIndex = this.ordoItems.indexOf(element) + 1;
      this.responseIndex.push(initialItemIndex);
    });
    this.args.answerChanged(this.responseIndex, this.items.length);
  }
}
