import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div \n{{did-insert this.initCodemirror}}\nid=\"codeEditor\"></div>", {"contents":"<div \n{{did-insert this.initCodemirror}}\nid=\"codeEditor\"></div>","moduleName":"mon-pix/components/challenge-code-statement.hbs","parseOptions":{"srcName":"mon-pix/components/challenge-code-statement.hbs"}});
import Component from '@glimmer/component';
import codemirror from "codemirror";
import "codemirror/mode/javascript/javascript";

export default class ChallengeCodeStatementComponent extends Component {
    initCodemirror(_element) {
        const editorContainer = document.getElementById('editorContainer');
        if(editorContainer !== null){
          const editorContent = editorContainer.getAttribute('content');
          codemirror(editorContainer, {
            lineNumbers: true,
            matchBrackets: true,
            mode: "javascript",
            readOnly: true,
            styleActiveLine: true,
            value: editorContent,
            viewportMargin: Infinity,
          });
        }
      }
}
