import Route from '@ember/routing/route';
import ENV from 'mon-pix/config/environment';

export default class LandingRoute extends Route {
  redirect() {
    if (ENV.APP.LANDING_URL) {
      window.location.replace(ENV.APP.LANDING_URL);
    } else {
      this.replaceWith('profile');
    }
  }
}
