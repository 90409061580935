import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div \n{{did-insert this.initCodemirror}}\nclass=\"code_proposals\"></div>", {"contents":"<div \n{{did-insert this.initCodemirror}}\nclass=\"code_proposals\"></div>","moduleName":"mon-pix/components/challenge-code-proposals.hbs","parseOptions":{"srcName":"mon-pix/components/challenge-code-proposals.hbs"}});
import Component from '@glimmer/component';
import codemirror from "codemirror";
import "codemirror/mode/javascript/javascript";
import forEach from "lodash/forEach"

export default class ChallengeCodeProposalsComponent extends Component {
    initCodemirror(_element) {
        const editorContainer = document.getElementsByClassName('choice_code');
        console.log(editorContainer);
        if(editorContainer.length > 0){
            forEach(editorContainer, function (element) {
                const editorContent = element.getAttribute('content');
                codemirror(element, {
                    lineNumbers: true,
                    lineWrapping: true,
                    matchBrackets: true,
                    mode: 'javascript',
                    readOnly: true,
                    styleActiveLine: true,
                    value: editorContent,
                    viewportMargin: Infinity,
                });
            });
        }
      }
}
