/* eslint ember/no-classic-components: 0 */
/* eslint ember/require-computed-property-dependencies: 0 */
/* eslint ember/require-tagless-components: 0 */

import Component from '@glimmer/component';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';

export default class CategSolutionPanel extends Component {
  answer = null;
  solutionToDisplay = null;
  challenge = null;

  get responseArray() {
    const responseArray = [];
    const answer = this.args.answer.get('value').split(',\n,');
    const solution = this.args.solutionToDisplay.split('\n');
    const proposalsAndCateg = this.args.challenge.get('proposals').split('--\n');
    const categs = proposalsAsArray(proposalsAndCateg[1]);
    const proposals = proposalsAsArray(proposalsAndCateg[2]);
    for (let indexCateg = 0; indexCateg < categs.length; indexCateg++) {
      const currentArrayResponse = [];
      const answerCurrentCateg = answer[indexCateg].split(',');
      const solutionCurrentCateg = solution[indexCateg].split(',');
      const currentCateg = categs[indexCateg];
      answerCurrentCateg.map((answ) => currentArrayResponse.push({ 'label' : proposals[answ - 1].replaceAll('<br>', ''), 'goodness' : solutionCurrentCateg.indexOf(answ) !== -1 }));
      responseArray.push({ 'name' : currentCateg, 'response' : currentArrayResponse });
    }
    return responseArray;
  }
}
