import { helper } from '@ember/component/helper';
import isArray from 'lodash/isArray';
import join from 'lodash/join';
import split from 'lodash/split';

export default helper(function addSpaceHtml(params) {
  if (!isArray(params) || params.length <= 0) {
    return '';
  }
  const text = params[0];
  const htmlFormated = join(split(text, /(?![A-Za-z]{1})</), ' <');
  return htmlFormated;
});
