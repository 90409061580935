import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ChallengeItemGeneric from './challenge-item-generic';

export default class ChallengeItemOrdo extends ChallengeItemGeneric {
  @service intl;
  answersValue = new Set();
  proposalsLength = 1;

  _hasError() {
    return this.answersValue.size < 1 || this.proposalsLength > 0;
  }

  _getAnswerValue() {
    return Array.from(this.answersValue).join(',');
  }

  _getErrorMessage() {
    return this.intl.t('pages.challenge.skip-error-message.ordo');
  }

  @action
  answerChanged(items, proposalsLength) {
    this.answersValue = new Set(items);
    this.proposalsLength = proposalsLength;
  }
}
