/* eslint ember/no-classic-components: 0 */
/* eslint ember/require-computed-property-dependencies: 0 */
/* eslint ember/require-tagless-components: 0 */

import Component from '@glimmer/component';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';


export default class OrdoSolutionPanel extends Component {
  answer = null;
  solutionToDisplay = null;
  challenge = null;

  get responseArray() {
    const responseArray = [];
    const solution = this.args.solutionToDisplay.split(',');
    const response = this.args.answer.get('value').split(',');
    const proposals = proposalsAsArray(this.args.challenge.get('proposals'));
    for (let ivalue = 0; ivalue < response.length; ivalue++) {
      const currentResponse = [];
      currentResponse.push(solution[ivalue] === response[ivalue]);
      currentResponse.push(proposals[Number(response[ivalue]) - 1].replace('<br>', ''));
      responseArray.push(currentResponse);
    }
    return responseArray;
  }
}
