import Route from '@ember/routing/route';
import ENV from 'mon-pix/config/environment';

export default class CoursesListRoute extends Route {
  model() {
    if (ENV.APP.COURSES_LIST_ENABLED) {
      const store = this.store;
      return store.findAll('course');
    } else {
      this.replaceWith('profile');
    }
  }
}
