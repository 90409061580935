import { action } from "@ember/object";
import { bind } from "@ember/runloop";
import codemirror from "codemirror";
import Modifier from "ember-modifier";

import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/selection/active-line";
import "codemirror/mode/clike/clike";
import "codemirror/mode/go/go";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";

const DoNotHighlight = "null";

export default class CodeMirrorModifier extends Modifier {
  didInstall() {
    this._setup();
  }

  _editor;

  _setup() {
    if (!this.element) {
      throw new Error("CodeMirror modifier has no element");
    }
    const editor = codemirror(this.element, {
      lineNumbers: true,
      matchBrackets: true,
      mode: this.args.named.mode || 'javascript',
      readOnly: this.args.named.readOnly,
      styleActiveLine: true,
      value: this.args.named.content,
      viewportMargin: Infinity,
    });

    editor.on("change", bind(this, () => console.log("change")));

    this._editor = editor;
  }
}