import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"update-email-with-validation\">\n  {{#if this.showEmailForm}}\n    <UserAccount::EmailWithValidationForm\n      @disableEmailEditionMode={{@disableEmailEditionMode}}\n      @showVerificationCode={{this.showVerificationCode}}\n    />\n  {{else}}\n    <UserAccount::EmailVerificationCode\n      @disableEmailEditionMode={{@disableEmailEditionMode}}\n      @displayEmailUpdateMessage={{@displayEmailUpdateMessage}}\n      @email={{this.newEmail}}\n      @password={{this.password}}\n    />\n  {{/if}}\n</div>", {"contents":"<div class=\"update-email-with-validation\">\n  {{#if this.showEmailForm}}\n    <UserAccount::EmailWithValidationForm\n      @disableEmailEditionMode={{@disableEmailEditionMode}}\n      @showVerificationCode={{this.showVerificationCode}}\n    />\n  {{else}}\n    <UserAccount::EmailVerificationCode\n      @disableEmailEditionMode={{@disableEmailEditionMode}}\n      @displayEmailUpdateMessage={{@displayEmailUpdateMessage}}\n      @email={{this.newEmail}}\n      @password={{this.password}}\n    />\n  {{/if}}\n</div>","moduleName":"mon-pix/components/user-account/update-email-with-validation.hbs","parseOptions":{"srcName":"mon-pix/components/user-account/update-email-with-validation.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UpdateEmailWithValidation extends Component {
  @service store;

  @tracked newEmail = '';
  @tracked password = '';
  @tracked showEmailForm = true;

  @action
  showVerificationCode({ newEmail, password }) {
    this.newEmail = newEmail.trim();
    this.password = password;
    this.showEmailForm = false;
  }
}
